$(document).ready( function() {

	$('.language-switch, .login').hover( function(e) {
		$(this).addClass('open-box');
	}, function () {
		$(this).removeClass('open-box');
	});
	$('.hamb-icon').on('click tap', function () {
		console.log('prova');
		if ($('.main-menu-collapse').hasClass('open-box')) {
			$('.main-menu-collapse').removeClass('open-box');
			return
		}
		$('.main-menu-collapse').addClass('open-box');
	});

	$(document).click( function() {
		$('.facilities > div').removeClass('open');
	});

	// element to detect scroll direction of
	var el = $(window),

  // initialize last scroll position
  lastY = el.scrollTop();

	el.on( 'scroll', function() {

			// get current scroll position
	    var currY = el.scrollTop(),

      // determine current scroll direction
      y = (currY > lastY) ? 'down' : ((currY === lastY) ? 'none' : 'up');

	    // do something here…
	    if( y == 'up' && !$('.main-nav').hasClass('sticky') ) {

				$('.main-nav').css( {"position":"", 'top':""} );
				$('.main-nav').addClass('sticky');

				$('.main-nav').css( {'top':-$('.main-nav').height()} );
				$('.main-nav').animate( {'top':0}, 200 );

			} else if( y == 'down' ) {

				if( $('.main-nav').hasClass('sticky') ) {

					$('.main-nav').removeClass('sticky');
					$('.main-nav').css( {'top':$(document).scrollTop(), 'position':'absolute'} );

				} else {

					// se esce da view rest top

				}

			}

	    // update last scroll position to current position
	    lastY = currY;

	} );
	//footer Mobile
	$('footer .nav-box').on('click tap', function() {
		if ($(this).hasClass('open')) {
			$(this).children('.title-box').children('.expand').children('img').attr('src', '/images/icons/expand-icon.svg')
			$(this).removeClass('open');
		} else {
			$(this).children('.title-box').children('.expand').children('img').attr('src', '/images/icons/minus-icon.svg')
			$(this).addClass('open');
		}
	})
} );
