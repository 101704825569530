function customSelect(select_selector) {
	// private vars
	var $ul,
		$options_wrapper,
		$first_option,
		$selected_option,
		$selected_options_holder,
		$hidden_input;
	var _this = this;

	var status = "closed";
	var open_direction;
	var cur_value;

	var max_height = 0;
	var options_height = 0;

	var initialize = function() {
		// transform select in ul
		$select = $(select_selector);

		var select_classes = $select.attr("class");
		var select_name = $select.attr("name");

		$ul = $("<ul></ul>");

		$select.find("option").each(function() {
			var $li = $(
				'<li data-value="' + $(this).val() + '">' + $(this).html() + "</li>"
			);

			if ($(this).is(":selected")) {
				$selected_option = $li;
				$selected_option.addClass("selected");
			}

			$ul.append($li);
		});

		$select.replaceWith($ul);

		$first_option = $ul.find("li").first();

		// enwrap ul in div.sld-select
		_this.$sld_select = $("<div></div>").addClass(
			"sld-select " + select_classes
		);
		$ul.after(_this.$sld_select);
		_this.$sld_select.append($ul);

		// add li to show current selection
		$selected_options_holder = $("<li></li>")
			.prependTo($ul)
			.addClass("sld-select__selected-option");

		// add hidden input to keep selected value
		$hidden_input = $(
			'<input type="hidden" name="' + select_name + '" value="" />'
		).appendTo(_this.$sld_select);

		// enwrap options
		$options_wrapper = $("<div></div>").addClass("sld-select__options-wrapper");
		$ul.append($options_wrapper);
		$selected_options_holder.nextAll("li").appendTo($options_wrapper);

		// set first option as current selection
		if (typeof $selected_option !== "undefined") selectOption($selected_option);
		else selectOption($first_option);

		// set select dimensions
		setSelectDimensions();

		setBaseStyling();

		initMouseEvents();
	};

	var setBaseStyling = function() {
		_this.$sld_select.height(min_height);
		$ul.css({
			overflow: "hidden",
			height: min_height,
			position: "absolute",
			/*'z-index':9999,*/ cursor: "pointer"
		});

		if (max_height > 400) {
			$options_wrapper.css({ "overflow-y": "scroll", height: 300 });
			max_height =
				$ul.find(".sld-select__options-wrapper").outerHeight() +
				$ul.find(".sld-select__selected-option").outerHeight();
		}
	};

	var initMouseEvents = function() {
		$selected_options_holder.click(function(evt) {
			evt.stopPropagation();

			toggleOpen();
		});

		$options_wrapper.find("li").click(function() {
			selectOption($(this));
		});

		$(document).on("click", closeList);
	};

	var setSelectDimensions = function() {
		var select_width = 0;

		$ul.find("li").each(function() {
			$li = $(this);

			if ($li.outerWidth() > select_width) select_width = $li.outerWidth();
		});

		_this.$sld_select.width(select_width);

		max_height =
			$ul.find(".sld-select__options-wrapper").outerHeight() +
			$ul.find(".sld-select__selected-option").outerHeight();
		min_height = $ul.find(".sld-select__selected-option").outerHeight();
		options_height = $ul.find(".sld-select__options-wrapper").outerHeight();
	};

	var selectOption = function($option) {
		$options_wrapper.find("li").removeClass("selected");
		$option.addClass("selected");

		$selected_options_holder.html($option.html());
		$selected_options_holder.attr("data-value", $option.data("value"));
		$hidden_input.val($option.data("value"));
		cur_value = $option.data("value");

		_this.$sld_select.trigger("select_option");
	};

	var toggleOpen = function() {
		if (status == "closed") {
			openList();
		} else if (status == "opened") {
			closeList();
		}
	};

	var openList = function() {
		status = "animating";

		// if( $ul.offset().top - $(window).scrollTop() + max_height > $(document).height() ) {
		// 	$ul.prepend( $ul.find(".sld-select__options-wrapper") );
		// 	$ul.css( {"margin-top":-max_height+min_height} );
		// }

		$ul.animate({ height: max_height }, 0, function() {
			status = "opened";
			_this.$sld_select.addClass("sld-select--opened");
		});
	};

	var closeList = function() {
		status = "animating";

		$ul.animate({ height: min_height }, 0, function() {
			$ul.css({ "margin-top": 0 });
			$ul.append($ul.find(".sld-select__options-wrapper"));
			_this.$sld_select.removeClass("sld-select--opened");
			status = "closed";
		});
	};

	// public
	this.getSelectedValue = function() {
		return cur_value;
	};

	initialize();

	return this;
}

module.exports = customSelect;
